#settings {
    position: absolute;
    width: 100%;
    height: 100%;

    transform: translateY(-100%);
    transition: transform 200ms ease-in-out;
    pointer-events: none;

    &.active {
        transform: translateY(0%);
    }

    .settings-inner {
        position: absolute;
        left: 50%;
        top: 50%;
        min-width: 50%;
        transform: translateX(-50%) translateY(-50%);
        pointer-events: all;

        background-color: $dark;
        opacity: 0.9;
        border: 2px solid $grey;

        padding: 2rem;

        .heading {
            margin: 0;
            margin-bottom: 0.75rem;
        }

        div {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            align-items: stretch;
            margin-top: 0.75rem;

            label {
                color: $grey-light;
            }

            &:has(input[type=checkbox]) {
                flex-direction: row;
            }
        }
    }
}