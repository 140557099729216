html,
body {
    height: 100%;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
}

body {
    padding: 1rem;
    background-color: $grey-light;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}