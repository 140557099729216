:root {
    font-family: 'Courier New', Courier, monospace;
}

::selection {
    background-color: $primary-light;
    color: $dark;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $grey-light;
}